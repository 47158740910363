import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import whiteLogo from '../../assets/images/logo-dark.png'
import formulateLogo from '../../assets/images/formulate-logo.png'
import LogoIcon from "./LogoIcon";

const Footer3 = () => {
    return (
        <>
            <section className="sc-footer-section sc-pt-200 sc-md-pt-170 sc-sm-pt-100">
                <div className="container">
                    <div className="row padding-bottom">
                        <Fade fade delay={100}>
                            <div className="col-lg-3 col-sm-6">
                                <LogoIcon style={{height:250}}/>
                            </div>
                        </Fade>
                        <Fade fade delay={200}>
                            <div className="col-lg-3 col-sm-6">
                                <div className="footer-about">
                                    {/*       <p className="footer-des">SendOTP.Dev API - Seamlessly Secure Your Apps: Instant SMS & Email OTP Integration Made Easy.</p>
                                   <p className="footer-des">
                                        Authentication made easy. Our powerful API replaces traditional transactional emails, enabling developers to integrate SMS, email, and voice OTPs seamlessly. Ideal for securing applications and improving user verification, our service offers a reliable, scalable, and cost-effective solution for businesses of all sizes.
                                    </p>
                                    <p className="footer-des">Need to efficiently manage millions of secure OTP verifications daily? SendOTP.Dev offers a cost-effective, scalable solution for developers and businesses of all sizes to enhance app security.</p>
                                    
                                    <p className="footer-des">SendOTP.Dev API - Streamline Your Security: Effortlessly Integrate SMS, Email, and Voice OTPs. Ditch traditional transactional emails for a comprehensive, developer-friendly solution that secures applications with ease. Our platform is designed for scalability and reliability, ensuring seamless verification processes for businesses of any size. Embrace enhanced security and user verification with SendOTP.dev.</p>
                                    */}
                                    
                                    <p className="footer-des">Built for developers, our platform leverages cutting-edge telecom and cloud technologies to provide a robust, reliable OTP service. Whether you're managing a handful or millions of verifications daily, SendOTP.Dev empowers your application with top-notch security without the complexity.</p>
                                
                                </div>
                            </div>
                        </Fade>
                    {/*    <Fade fade delay={200}>
                            <div className="col-lg-3 col-sm-6 sc-xs-mt-40">
                                <div className="footer-menu-area sc-pl-90 sc-lg-pl-0 sc-md-pl-0 sc-md-mb-30 sc-sm-mb-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">Get Started</h4>
                                    <ul className="footer-menu-list">
                                        <li><Link to="/docs#">Send OTP API Documentation</Link></li>
                                        <li><Link to="/pricing#">Pricing</Link></li>
                                        <li><Link to="/faq#">Guides & FAQ</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>*/}
                        <Fade fade delay={300}>
                            <div className="col-lg-3 col-sm-6 sc-sm-mt-40">
                                <div className="footer-menu-area sc-pl-90 sc-lg-pl-0 sc-md-pl-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">Resources</h4>
                                    <ul className="footer-menu-list">
                                        <li><Link to="/docs#">Send OTP API Documentation</Link></li>
                                        <li><Link to="/pricing#">Pricing</Link></li>
                                        <li><Link to="/faq#">Guides & FAQ</Link></li>
                                        <li><a href="https://sendotp.dev/privacy" target={'_blank'}>Privacy Policy</a></li>
                                        <li><a href="https://sendotp.dev/terms" target={'_blank'}>Terms & Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                        <Fade fade delay={400}>
                            <div className="col-lg-3 col-sm-6 sc-sm-mt-40">
                                <div className="footer-menu-area footer-menu-area-left sc-pl-65 sc-lg-pl-0 sc-md-pl-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">Get In Touch</h4>
                                    <ul className="footer-menu-list">
                                        <li><i className="ri-mail-fill"></i><a href="mailto:hello@yourmail.com">hello@formulatelabs.com</a></li>
                                        <li><i className="ri-phone-fill"></i><a href="tel:18442624828">(844) 262 4828</a></li>
                                        <li className="footer-map"><i className="ri-map-pin-fill"></i><span>Florida, USA</span></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                    </div>
                    <p className="footer-des">Streamline your app's security with SendOTP.Dev, the developer-friendly API for instant OTP verification via SMS, email, and voice. Eliminate reliance on traditional transactional methods with our scalable, cost-effective solution designed for seamless integration and enhanced user verification.</p>
                
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="border-top"></div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-text text-center">
                                    <p>&copy; {new Date().getFullYear()} <Link to="#"> Formulate Labs, Inc.&nbsp;&nbsp;|&nbsp;&nbsp;</Link> All Rights Reserved.</p>
                                    <a href="https://FormulateLabs.com" target={'_blank'}><img style={{marginTop:10, height:50}} src={formulateLogo} alt="Formulate Logo" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Footer3;
