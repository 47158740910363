import React, {useCallback, useEffect, useState} from 'react';
import { Fade } from 'react-reveal';
//import ModalVideo from 'react-modal-video';
import { HashLink as Link } from 'react-router-hash-link';
import bannerBG from '../../../assets/images/banner/banner-bg.png';
import bannerBG2 from '../../../assets/images/banner/banner-bg2.png';
import bannerBG3 from '../../../assets/images/banner/banner-bg3.png';
import TypeWriterEffect from 'react-typewriter-effect';

const Banner = () => {
    const [isOpen, setOpen] = useState(false)
    
    const [typingText, setTypingText] = useState('api.sendotp.dev/v1/auth?url=google.com');
    
    const texts = [
        {
            url: 'google.com',
            width: 400,
            height: 800,
        },
        {
            url: 'google.com',
            locale: 'es',
        },
        {
            url: 'apple.com',
            width: 600,
            height: 1000,
        },
    ];
    
    const changeText = useCallback(() => {
        let index = (texts.findIndex((text) => text.url === typingText.split('=')[1]) + 1) % texts.length;
        const val = `api.sendotp.dev/v1/auth?url=${texts[index].url}${texts[index].width ? `&width=${texts[index].width}` : ''}${texts[index].height ? `&height=${texts[index].height}` : ''}${texts[index].locale ? `&locale=${texts[index].locale}` : ''}`
        console.log(val)
        setTypingText(val);
    }, [typingText]);
    
    return (
        <>
            <section className="sc-banner-section-area bottom-skew-bg sc-pt-200 sc-sm-pt-150 sc-pb-50 sc-sm-pb-70">
                <Fade top>
                    <div className="banner-circle-shape"></div>
                </Fade>
                <div className="container-fluid p-0">
                    <div className="row sc-banner-item d-flex align-items-center">
                        <div className="col-md-7">
                            <div className="banner-header-content">
                                <Fade left>
                                    <div className="banner-sub-title white-dark-color sc-mb-10 sc-sm-mb-25">
                                        Effortlessly easy - No more transactional emails!
                                    </div>
                                    <h1 className="banner-title p-z-idex white-color sc-mb-10 sc-sm-mb-15">Send OTP API</h1>
                                    <div className="banner-sub-title white-dark-color sc-mb-40 sc-sm-mb-25">
                                        <b>Securely</b> and <b>quickly</b> integrate Email, SMS or Voice based <b>One-Time Passwords</b> into your applications.
                                    </div>
                                </Fade>
                                <div className="banner-btn-area d-flex align-items-center">
                                    <Link className="primary-btn2" smooth to="/pricing#"><span>Get Started</span></Link>
                                   {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="LlCwHnp3kL4" onClose={() => setOpen(false)} />
                                    <Link className="popup-video" onClick={() => setOpen(true)}>
                                        <i className="ri-play-fill"></i>
                                        <span>Watch Intro
                                        </span></Link>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-none">
                            <div className="banner-image text-end shake-y">
                                <img className="banner-img" src={bannerBG} alt="Banner" />
                                <img className="banner-img_1 pulse" src={bannerBG2} alt="Banner" />
                                <img className="banner-img_2 pulse" src={bannerBG3} alt="Banner" />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="banner-image text-end shake-y w-75">
                                <div className={'react-typewriter-text-wrap'}>
                                    <h1 className={'react-typewriter-text'}>
                                        api.sendotp.dev/v1/
                                    </h1>
                                </div>
                                <TypeWriterEffect
                                   cursorColor="white"
                                   eraseSpeed={100}
                                   startDelay={2000}
                                   multiTextLoop={true}
                                   multiText={[
                                       'auth?phone_number=+15555555555',
                                       'confirm?token=1234&email=user@example.com',
                                   ]}
                                   multiTextDelay={1000}
                                   typeSpeed={30}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-shape"></div>
            </section>
        </>
    );
};

export default Banner;
