import React, {useMemo, useState} from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';

const PricePlanDeprecated = () => {
    let [priceToggle, setPriceToggle] = useState(true)
    const production = useMemo(()=>window.location.hostname === 'sendotp.dev',[]);
    return (
        <>
            <section className="sc-price-area gray-bg sc-pt-125 sc-md-pt-65 sc-pb-140 sc-md-pb-80">
                <div className="container">
                    <div className="sc-heading-area sc-mb-40 sc-md-mb-30 text-center">
                        <h2 className="title sc-mb-25">Super Simple Pricing</h2>
                        <span className="sub_title">No Hidden Fees, No Limits</span>
                    </div>{/*
                    <div className="pricing-selector">
                        <form >
                            <button type="button" className="pricing-monthly-btn">Monthly</button>
                            <input type="checkbox" name="pricing-selector" id="pricing-selector" onChange={() => setPriceToggle(!priceToggle)} />
                            <button type="button" className="pricing-yearly-btn">Yearly <span>(35% Off)</span></button>
                        </form>
                    </div>*/}
                    <div className={`${priceToggle ? 'pricing-monthly' : 'pricing-monthly d-none'}`}>
                        <div className="row">
                            <Fade bottom delay={100}>
                                <div className="col-lg-3 col-md-6 sc-md-mb-30">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Developer</h4>
                                            <div className="price-active">$7<span className="month"> - Month</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Developers</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>100 OTP Requests</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>SMS Authentication</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Email Authentication</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Voice Authentication*</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Email Support</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Dedicated Support</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <a className="price-btn" href={production ? "https://buy.stripe.com/aEU6s94msddn7y8cMM" : "https://buy.stripe.com/test_9AQ5m51mUc887jqaEE"}><span>Start Trial</span></a>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={200}>
                                <div className="col-lg-3 col-md-6 sc-md-mb-30" >
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Starter</h4>
                                            <div className="price-active price">$15<span className="month"> - Month</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for MVPs</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>10,000 OTP Requests</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>SMS Authentication</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Authentication</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Voice Authentication*</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Support</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Dedicated Support</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <a className="price-btn" href={production ? "https://buy.stripe.com/9AQ6s92ekgpz2dO001" : "https://buy.stripe.com/test_9AQ01L8Pm1tueLSaEF"}><span>Start Trial</span></a>
                                        </div>
                                        <div className="price_sale">
                                            <span className="popular">popular</span>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={300}>
                                <div className="col-lg-3 col-md-6">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Growth</h4>
                                            <div className="price-active price">$99<span className="month"> - Month</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Startups</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>50,000 OTP Requests</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>SMS Authentication</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Authentication</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Voice Authentication*</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Support</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Dedicated Support</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <a className="price-btn" href={production ? "https://buy.stripe.com/4gw4k11aga1b2dObIK" : "https://buy.stripe.com/test_bIY7udaXu0pq47e9AC"}><span>Start Trial</span></a>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={400}>
                                <div className="col-lg-3 col-md-6">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Pro</h4>
                                            <div className="price-active price">$180<span className="month"> - Month</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Business</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>100,000 OTP Requests</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>SMS Authentication</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Authentication</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Voice Authentication*</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Support</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Dedicated Support</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <a className="price-btn" href={production ? "https://buy.stripe.com/cN27wd9GMehrdWw3cf" : "https://buy.stripe.com/test_00geWF6He1tu6fmfZ1"}><span>Start Trial</span></a>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className={`${priceToggle ? 'pricing-yearly d-none' : 'pricing-yearly'}`}>
                        <div className="row">
                            <Fade bottom delay={100}>
                                <div className="col-lg-4 col-md-6 sc-md-mb-30">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Free</h4>
                                            <div className="price-active">$25.00<span className="month"> - Year</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Personal</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>1 Users</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>SaaS Metrics</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Team Collaboration</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Upload Your Document</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Export PDF</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact#"><span>Try It’s Free</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={200}>
                                <div className="col-lg-4 col-md-6 sc-md-mb-30">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Basic</h4>
                                            <div className="price-active price">$89.99<span className="month"> - Year</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Prosonal</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i>1 Users</li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>SaaS Metrics</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>Team Collaboration</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Upload Your Document</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Export PDF</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact#"><span>Try Now</span></Link>
                                        </div>
                                        <div className="price_sale">
                                            <span className="popular">popular</span>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={300}>
                                <div className="col-lg-4 col-md-6">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Pro</h4>
                                            <div className="price-active price">$99.99<span className="month"> - Year</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Prosonal</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i>1 Users</li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>SaaS Metrics</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>Team Collaboration</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>Upload Your Document</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Export PDF</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact#"><span>Try Now</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
const PricePlan = () => {
    let [priceToggle, setPriceToggle] = useState(true)
    const production = useMemo(()=>window.location.hostname === 'sendotp.dev',[]);
    return (
        <>
            <section className="sc-price-area gray-bg sc-pt-125 sc-md-pt-65 sc-pb-140 sc-md-pb-80">
                <div className="container">
                    <div className="sc-heading-area sc-mb-40 sc-md-mb-30 text-center">
                        <h2 className="title sc-mb-25">Super Simple Pricing</h2>
                        <span className="sub_title">No Hidden Fees, No Limits</span>
                    </div>{/*
                    <div className="pricing-selector">
                        <form >
                            <button type="button" className="pricing-monthly-btn">Monthly</button>
                            <input type="checkbox" name="pricing-selector" id="pricing-selector" onChange={() => setPriceToggle(!priceToggle)} />
                            <button type="button" className="pricing-yearly-btn">Yearly <span>(35% Off)</span></button>
                        </form>
                    </div>*/}
                    <div className={`${priceToggle ? 'pricing-monthly' : 'pricing-monthly d-none'}`}>
                        <div className="row">
                            <Fade bottom delay={100}>
                                <div className="col-lg col-md-6 sc-md-mb-30">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Developer</h4>
                                            <div className="price-active price">$7<span className="month"> - Month</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Developers</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>100 OTP Requests</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Authentication</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>SMS Authentication</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Voice Authentication*</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Email Support</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Dedicated Support</li>
                                                <li><i className="ri-information-fill"></i>Overage: $0.10 per OTP</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <a className="price-btn" href={production ? "https://buy.stripe.com/8wM6qB6R49Dp74sdQQ" : "https://buy.stripe.com/test_5kA6pwcOo19N7lK6op"}><span>Start Trial</span></a>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={200}>
                                <div className="col-lg col-md-6 sc-md-mb-30" >
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Starter</h4>
                                            <div className="price-active">$15<span className="month"> - Month</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for MVPs</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>10,000 OTP Requests</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Authentication</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>SMS Authentication</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Voice Authentication*</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Support</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Dedicated Support</li>
                                                <li><i className="ri-information-fill"></i>Overage: $0.05 per OTP</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <a className="price-btn" href={production ? "https://buy.stripe.com/4gw9CN7V8aHtgF28wx" : "https://buy.stripe.com/test_5kA6pwcOo19N7lK6op"}><span>Start Trial</span></a>
                                        </div>
                                        <div className="price_sale">
                                            <span className="popular">popular</span>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={300}>
                                <div className="col-lg col-md-6">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Growth</h4>
                                            <div className="price-active price">$99<span className="month"> - Month</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Startups</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>50,000 OTP Requests</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Authentication</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>SMS Authentication</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Voice Authentication*</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Support</li>
                                                <li><i className="ri-checkbox-blank-circle-line"></i>Dedicated Support</li>
                                                <li><i className="ri-information-fill"></i>Overage: $0.04 per OTP</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <a className="price-btn" href={production ? "https://buy.stripe.com/aEU7uFb7k6rd2Oc4gi" : "https://buy.stripe.com/test_5kA6pwcOo19N7lK6op"}><span>Start Trial</span></a>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={400}>
                                <div className="col-lg col-md-6">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Pro</h4>
                                            <div className="price-active price">$195<span className="month"> - Month</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Business</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>110,000 OTP Requests</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Authentication</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>SMS Authentication</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Voice Authentication*</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Support</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Dedicated Support</li>
                                                <li><i className="ri-information-fill"></i>Overage: $0.03 per OTP</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <a className="price-btn" href={production ? "https://buy.stripe.com/14kbKV2AO4j5bkI9AD" : "https://buy.stripe.com/test_5kA6pwcOo19N7lK6op"}><span>Start Trial</span></a>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={500}>
                                <div className="col-lg col-md-6">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Enterprise</h4>
                                            <div className="price-active price">$299<span className="month"> - Month</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Scale</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>225,000 OTP Requests</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Authentication</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>SMS Authentication</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Voice Authentication*</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Email Support</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Dedicated Support</li>
                                                <li><i className="ri-information-fill"></i>Overage: $0.01 per OTP</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <a className="price-btn" href={production ? "https://buy.stripe.com/eVa8yJb7kcPB3Sg004" : "https://buy.stripe.com/test_5kA6pwcOo19N7lK6op"}><span>Start Trial</span></a>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className={`${priceToggle ? 'pricing-yearly d-none' : 'pricing-yearly'}`}>
                        <div className="row">
                            <Fade bottom delay={100}>
                                <div className="col-lg-4 col-md-6 sc-md-mb-30">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Free</h4>
                                            <div className="price-active">$25.00<span className="month"> - Year</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Personal</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>1 Users</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>SaaS Metrics</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Team Collaboration</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Upload Your Document</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Export PDF</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact#"><span>Try It’s Free</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={200}>
                                <div className="col-lg-4 col-md-6 sc-md-mb-30">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Basic</h4>
                                            <div className="price-active price">$89.99<span className="month"> - Year</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Prosonal</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i>1 Users</li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>SaaS Metrics</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>Team Collaboration</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Upload Your Document</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Export PDF</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact#"><span>Try Now</span></Link>
                                        </div>
                                        <div className="price_sale">
                                            <span className="popular">popular</span>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={300}>
                                <div className="col-lg-4 col-md-6">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Pro</h4>
                                            <div className="price-active price">$99.99<span className="month"> - Year</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Prosonal</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i>1 Users</li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>SaaS Metrics</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>Team Collaboration</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>Upload Your Document</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Export PDF</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact#"><span>Try Now</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PricePlan;
