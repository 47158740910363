import React, {useMemo, useState} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../../assets/images/logo.png'
import logoDark from '../../assets/images/logo-dark.png';
import OffCanvasMenu from './OffCanvasMenu';
import MenuItems from './MenuItems';
import LogoIcon from "./LogoIcon";

const Header = (props) => {
    const { parentMenu, headerClass, headerNormalLogo, headerStickyLogo, signUpBtn } = props;

    const [stickyHeader, setStickyHeader] = useState(false)
    let sticky = () => {
        if (window.scrollY > 150) {
            setStickyHeader(true)
        } else {
            setStickyHeader(false)
        }
    }
    window.addEventListener("scroll", sticky)
    const production = useMemo(()=>window.location.hostname === 'sendotp.dev',[]);
    return (
        <>
            <header className={stickyHeader ? headerClass + " sc-header-sticky" : headerClass} id="sc-header-sticky">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-8 col-md-4 col-sm-3">
                            <div className="sc-header-left-wraper d-flex align-items-center">
                                <div className="sc-main-logo sc-mr-60 sc-md-mr-30 text-center text-sm-start">
                                    <div className="default-logo logo-area">
                                        <Link to="/#">
                                            <LogoIcon style={{height:160}}/>
                                   {/*         <img className="d-none d-md-block" src={headerNormalLogo ? headerNormalLogo : logo} style={{height: 20}} alt="logo" />
                                            <img className="d-block d-md-none" src={headerNormalLogo ? headerNormalLogo : logoDark} style={{height: 20}} alt="logo" />
                                      */}
                                        </Link>
                                    </div>
                                    <div className="sticky-logo logo-area">
                                        <Link to="/#">
                                            <LogoIcon style={{fill:'red'}}/>
                                            {/*<img src={headerStickyLogo ? headerStickyLogo : logo} style={{height: 20}} alt="logo" />*/}
                                        </Link>
                                    </div>
                                </div>
                                <div className="sc-main-menu d-lg-block d-none">
                                    <ul className="main-menu">
                                        <MenuItems
                                            parentMenu={parentMenu}
                                        />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* OffCanvas Area  */}
                        <div className="col-lg-4 col-md-8 col-sm-9">
                            <div className="header-btn-area p-z-idex d-flex align-items-center justify-content-center justify-content-sm-end">
                                <div className="header-list">
                                    <ul className='d-flex '>
                                        <li className="canvas sc-pr-20 sc-sm-pr-10 text-white d-lg-none">
                                            {/*<OffCanvasMenu></OffCanvasMenu>*/}
                                        </li>
                                        <li>
                                            <a href={production ? "https://billing.stripe.com/p/login/4gwg2jf5N8eBbU46oo" : "https://billing.stripe.com/p/login/test_7sI4jh5yCbsgggw3cc"}><i className="ri-lock-line"></i>Login </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="header-btn2 sc-pl-30 sc-sm-pl-20">
                                    <Link className={signUpBtn ? signUpBtn : "sign-in-btn"} to="/pricing#"><span>Get Started</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
